import React from 'react';
import HeroBanner from '../../components/sections/hero-banner';
import { useStaticQuery, graphql } from 'gatsby';
import { Benefit, Benefits } from '../../models/benefit';
import BenefitSection from '../../components/sections/industry/benefit';
import WhyPatronscan from '../../components/sections/why-patronscan';
import SEO from '../../components/seo';
import Feature from '../../components/sections/industry/feature-block';
import ConnectWithUs from '../../components/sections/connect-with-us';
import { getDemoLink } from '../../services/demo-selector';
import './vehicle-rental.css';
import { Route } from '../../models/site-links';

const VehicleRentalIndustry = () => {
  const data = useStaticQuery(graphql`
    query {
      hero: file(relativePath: { eq: "hero-images/fake-ID-hero.jpg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      kioskPlus: file(relativePath: { eq: "product-card/kiosk-plus.png" }) {
        childImageSharp {
          fixed(height: 118) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      fraudster: file(relativePath: { eq: "anti-fraud/Fraudster-latest.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      kiosk: file(relativePath: { eq: "feature-images/white-scanner.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      barcodeScanning: file(relativePath: { eq: "feature-images/barcode_no_bueno.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      blackCountertop: file(relativePath: { eq: "benefit-images/black-countertop.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      carRentalAlert: file(relativePath: { eq: "benefit-images/car-rental-alert.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      demographics: file(relativePath: { eq: "benefit-images/demographics.jpg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      spaceSavingDesign: file(relativePath: { eq: "telco/spaceSaving.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      creditCardFrd: file(relativePath: { eq: "anti-fraud/credit-card-processing.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      secureandpvt: file(relativePath: { eq: "feature-images/securePrivateSq.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      lfprivacy: file(relativePath: { eq: "feature-images/scanners/lf-privacy.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      cPrivacy: file(relativePath: { eq: "feature-images/scanners/c-privacy.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      lffakeID: file(relativePath: { eq: "feature-images/scanners/lf-fakeID.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      lfhHome: file(relativePath: { eq: "feature-images/scanners/lfh-home.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const benefits: Benefits[] = [
    {
      image: data.blackCountertop.childImageSharp.fluid,
      benefits: [
        {
          heading: 'Advanced Multi-Layer ID Verification',
          text: 'Fake IDs get better everyday, so does Patronscan.  Our proprietary software scans the front of the ID and identifies which IDs are real and which are fake. Scanners that only scan barcodes are easily fooled. Using Patronscan is the best way to protect yourself from fraudsters.',
        },
        {
          heading: 'Read All Types of Identification',
          text: 'You want your ID scanner to be as effective as possible, which means scanning all types of IDs. We scan all types of government issues IDs including: driver’s licenses, military cards, passports and international IDs. Patronscan has the ability to scan over 4,500 different IDs from around the world and that number keeps growing.',
        },
        {
          heading: 'High Speed Scanning',
          text: 'Patronscan makes checking IDs simple. In 3-4 seconds your staff will know everything they need to about the potential customer standing in front of them. Verify your customers identity without creating more work for your staff.',
        },
      ],
    },
    {
      image: data.carRentalAlert.childImageSharp.fluid,
      benefits: [
        {
          heading: 'Powerful Shared Flag Network',
          text: 'Identifying fraudsters before they act is difficult. The Patronscan network will alert you when someone has been flagged by another location for fraudulent behavior.  Your locations are notified of exactly when and why the flag was placed, preventing further damages.',
        },
        {
          heading: 'Scan History',
          text: 'Using Patronscan at your locations will provide better insights into your customers, increasing their accountability.   Your scan history includes names and live images making it easy to go through and point out exactly who was causing trouble at your location.',
        },
        {
          heading: 'Real-Time Alerts',
          text: 'There are times when you need information immediately. Staff can send out real time text message alerts from our system.',
        },
      ],
    },
  ];

  let sectionCounter = 0;
  return (
    <div>
      <SEO
        title="ID Scanners for Car Dealerships | Patronscan"
        description="Prevent financial and rental fraud by spotting fake IDs with the industry’s best ID scanners. Scan over 4,800 different IDs."
      />
      <HeroBanner
        heroImage={data.hero.childImageSharp.fluid}
        heading="Trusted Fraud Prevention"
        subHeading="Best in class fake ID detection for your car dealership."
        videoLink="https://player.vimeo.com/video/469962195?autoplay=0&loop=0&autopause=0&muted=0"
        showVideo
        linkText="Book Demo"
        link={getDemoLink()}
        buttonId="AutomotiveHero:BookDemo"
      />
      <section>
        <Feature
          image={data.fraudster.childImageSharp.fluid}
          heading="Fraudsters rely"
          headingThin="on fake IDs"
          subHeading="Synthetic identity fraud is one of the fastest-growing types of fraud across the industry."
          description=" Fraudsters use a combination of real and fake personal data to create identities to steal cars. Protect your dealership with ID scanners proven to catch sophisticated fake IDs."
          linkText="Contact Us"
          link={Route.TalkToAnExpert}
          buttonId="AutomotiveFeature:FraudstersRelyFakeIds"
        />
        <Feature
          rightAlign
          image={data.lffakeID.childImageSharp.fluid}
          heading="Reliable and accurate"
          headingThin="fake ID detection"
          subHeading="Patronscan ID scanners are trusted worldwide to catch fraudulent identity documents."
          description="Modern fake IDs replicate barcodes, holograms and fluorescent overlays. Detect pixel-level discrepancies on over 4,500 ID types using Patronscan's proprietary scanning engine."
          linkText="Book Demo"
          link={getDemoLink()}
          buttonId="AutomotiveFeature:ReliableAndAccurate"
        />
        <Feature
          image={data.lfhHome.childImageSharp.fluid}
          heading="Process credit cards"
          headingThin="with confidence"
          subHeading="Prevent credit card and fraudulent vehicle purchases by verifying identity documents."
          description="Ensure people are who they claim to be before finalizing sales. Avoid false pretense claims and chargebacks by incorporating Patronscan in to your risk management practices."
          linkText="Get Pricing"
          link={Route.GetPricing}
          buttonId="AutomotiveFeature:ProcessCreditCards"
        />
        <Feature
          rightAlign
          image={data.cPrivacy.childImageSharp.fluid}
          heading="Secure and private"
          headingThin="ID verification"
          subHeading="Comply with North American privacy laws when verifying the IDs of potential customers."
          description="Rest assured that the data collected by Patronscan ID scanners is compliant, encrypted and temporarily stored in top-tier SSAE 16 type 2 facilities."
          linkText="Get Pricing"
          link={Route.GetPricing}
          buttonId="AutomotiveFeature:SecureAndPrivate"
        />
      </section>
      <section>
        <h2 className="p-4 lg:p-16 gradient-text text-3xl lg:text-6xl leading-tight text-center font-hairline font-heading">
          More Patronscan Benefits
        </h2>
      </section>
      <section>
        {benefits.map((benefit) => {
          sectionCounter++;
          return (
            <BenefitSection benefits={benefit} alignRight={sectionCounter % 2 ? true : false} />
          );
        })}
      </section>
      <div className="cars-why-patronscan">
        <WhyPatronscan />
      </div>
      <ConnectWithUs
        buttonId="AutomotiveConnect"
        promptText="Get in touch to get pricing information or see how Patronscan protects car dealerships from fraud."
      />
    </div>
  );
};
export default VehicleRentalIndustry;
