import React, { FC } from 'react';
import { Benefits, Benefit } from '../../../models/benefit';
import Img from 'gatsby-image';

interface BenefitSectionProps {
    alignRight?: boolean;
    benefits: Benefits;
}
const BenefitSection: FC<BenefitSectionProps> = (props) => {
    const classes = props.alignRight ? 'md:bg-blue-light' : 'md:text-right ml-auto'
    return (
        <div className={`flex flex-col-reverse flex-wrap items-center justify-between ${props.alignRight ? 'md:flex-row' : 'md:flex-row-reverse'}`}>
            <div className={`md:w-1/2 lg:w-6/12 ${classes}`}>
                {props.benefits.benefits.map((benefit: Benefit) => (
                    <div className={`my-8 p-4 lg:px-16`}>
                        <h3 className="text-blue-benefit mb-4 text-xl font-medium font-heading">{benefit.heading}</h3>
                        <p className="inline-block text-grey-darker max-w-2xl">{benefit.text}</p>
                    </div>
                ))}
            </div>
            <div className="w-full md:w-1/2 lg:w-5/12">
                <Img fluid={props.benefits.image} />
            </div>
        </div>
    )
}

export default BenefitSection;